// project import
import useAuth from 'hooks/useAuth';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  GroupOutlined,
  TeamOutlined,
  SettingOutlined,
  DashboardOutlined,
  ExportOutlined,
  ImportOutlined,
  FilterOutlined,
  FacebookOutlined,
  BuildOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch';
import { dashboardsByClientID } from 'graphql/queries';

import {
  Groups2Outlined,
  PersonOutlineOutlined,
  ReceiptOutlined,
  CandlestickChartOutlined,
  CalendarMonthOutlined,
  MapOutlined,
  FilterAltOutlined,
  PointOfSaleOutlined,
  PriceChangeOutlined,
  SsidChartOutlined,
  GpsFixedOutlined,
  FindInPageOutlined,
  ContactsOutlined,
  CottageOutlined,
  AddIcCallOutlined,
  FileUploadOutlined,
  FileDownloadOutlined,
  Google,
  TroubleshootOutlined,
  ShowChartOutlined,
  BusinessOutlined,
} from '@mui/icons-material/';
import { FacebookRounded } from '@mui/icons-material';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  GroupOutlined,
  TeamOutlined,
  SettingOutlined,
  DashboardOutlined,
  ExportOutlined,
  ImportOutlined,
  FilterOutlined,
};

// ==============================|| MENU ITEMS ||============================== //
function Menu() {
  const { user } = useAuth();
  const { listData, executeListQuery, listLoading } = useFetch();
  const [dashboards, setDashboards] = useState();
  const fetchDashboards = () => {
    // if (user?.Clients?.items?.length) {
    const fetchData = async () => {
      await executeListQuery(dashboardsByClientID, 'dashboardsByClientID', {
        ClientID: user?.clientView,
      });
    };
    fetchData();
  };
  useEffect(() => {
    if (user?.clientView) {
      fetchDashboards();
    }
  }, [user]);
  useEffect(() => {
    if (listData?.dashboardsByClientID?.length && !listLoading) {
      let tempDashes = listData.dashboardsByClientID;
      let categories = {};
      tempDashes?.forEach((dash) => {
        if (dash?.category) {
          categories[dash.category] = 1;
        }
      });
      tempDashes = tempDashes?.map((dash) => {
        if (!dash._deleted) {
          return {
            id: dash.title,
            title: dash.title,
            type: 'item',
            url: `/dashboard/${dash.id}`,
            icon: icons.DashboardOutlined,
            perms: ['CLIENT'],
            category: dash.category,
          };
        }
      });
      // console.log(tempDashes);
      if (Object?.keys(categories)?.length) {
        let dashes = Object?.keys(categories)
          ?.slice()
          ?.map((cat) => {
            return {
              id: cat,
              title: cat,
              type: 'group',
              children: tempDashes?.filter((d) => {
                return d.category === cat;
              }),
            };
          });
        // console.log(dashes);
        if (dashes?.length) {
          setDashboards(dashes);
        }
      }
    }
  }, [listData?.dashboardsByClientID, listLoading, user]);

  const dataTab = {
    id: 'data',
    title: 'Data',
    type: 'group',
    children: [
      {
        id: 'Import',
        title: 'Import',
        type: 'item',
        url: `/import/${user?.clientView}`,
        icon: FileUploadOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'Export',
        title: 'Export',
        type: 'item',
        url: `/export/${user?.clientView}`,
        icon: FileDownloadOutlined,
        perms: ['CLIENT'],
      },
    ],
  };

  const controlsTab = {
    id: 'controls',
    title: 'Controls',
    type: 'group',
    children: [
      {
        id: 'capacityThrottle',
        title: 'Capacity Throttle',
        type: 'item',
        url: '/dashboard/capacitythrottle',
        icon: CandlestickChartOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'capacityReport',
        title: 'Capacity Report',
        type: 'item',
        url: '/dashboard/capacityreport',
        icon: CalendarMonthOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'locationsReport',
        title: 'Locations Report',
        type: 'item',
        url: '/dashboard/locationsreport',
        icon: MapOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'funnelDiagnostics',
        title: 'Funnel Diagnostics',
        type: 'item',
        url: '/dashboard/funneldiagnostics',
        icon: FilterAltOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'revenueAttribution',
        title: 'Revenue Attribution',
        type: 'item',
        url: '/dashboard/revenueattribution',
        icon: PointOfSaleOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'budgetTracker',
        title: 'Budget Tracker',
        type: 'item',
        url: '/dashboard/budgettracker',
        icon: PriceChangeOutlined,
        perms: ['CLIENT'],
      },
    ],
  };

  const marketingTab = {
    id: 'marketing',
    title: 'Marketing',
    type: 'group',
    children: [
      {
        id: 'marketingOverview',
        title: 'Marketing Overview',
        type: 'item',
        url: '/dashboard/marketingoverview',
        icon: SsidChartOutlined,
        perms: ['CLIENT'],
      },
      /*{
        id: 'googleAds',
        title: 'Google Ads',
        type: 'item',
        url: '/dashboard/googleads',
        icon: Google,
        perms: ['CLIENT'],
      },
      {
        id: 'googleLSAAds',
        title: 'Google LSA Ads',
        type: 'item',
        url: '/dashboard/googlelsaads',
        icon: GpsFixedOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'microsoftAds',
        title: 'Microsoft Ads',
        type: 'item',
        url: '/dashboard/microsoftads',
        icon: FindInPageOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'facebookAds',
        title: 'Facebook Ads',
        type: 'item',
        url: '/dashboard/facebookads',
        icon: FacebookRounded,
        perms: ['CLIENT'],
      },*/
    ],
  };

  const directoryTab = {
    id: 'Directory',
    title: 'Directory',
    type: 'group',
    children: [
      {
        id: 'Clients',
        title: 'Clients',
        type: 'item',
        url: '/clients',
        icon: Groups2Outlined,
        perms: ['TENANT'],
      },
      {
        id: 'Contacts',
        title: 'Contacts',
        type: 'item',
        url: '/contacts',
        icon: ContactsOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'Locations',
        title: 'Locations',
        type: 'item',
        url: '/locations',
        icon: CottageOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'Leads',
        title: 'Leads',
        type: 'item',
        url: '/leads',
        icon: AddIcCallOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'BookedJobs',
        title: 'Booked Jobs',
        type: 'item',
        url: '/jobs',
        icon: CalendarMonthOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'Users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: PersonOutlineOutlined,
        perms: ['TENANT'],
      },
      {
        id: 'Integrations',
        title: 'Integrations',
        type: 'item',
        url: '/integrations',
        icon: icons.DeploymentUnitOutlined,
        perms: ['TENANT'],
      },
      {
        id: 'Tenants',
        title: 'Tenants',
        type: 'item',
        url: '/admin/tenants',
        icon: BusinessOutlined,
        perms: ['ADMIN'],
      },
      {
        id: 'Admin Users',
        title: 'Admin Users',
        type: 'item',
        url: '/admin/users',
        icon: PersonOutlineOutlined,
        perms: ['ADMIN'],
      },
    ],
  };

  const dashboardTab = {
    id: 'dashboards',
    title: 'Dashboards',
    type: 'group',
    children: [
      /*{
        id: "buCapacityReportDashboard",
        title: "Business Unit Capacity Report",
        type: "item",
        url: "/dashboard/bucapacityreportdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "marketingOverviewDashboard",
        title: "Marketing Dashboard",
        type: "item",
        url: "/dashboard/marketingoverviewdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "googleAdsDashboard",
        title: "Google Ads Dashboard",
        type: "item",
        url: "/dashboard/googleadsdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "googleLSADashboard",
        title: "Google LSA Dashboard",
        type: "item",
        url: "/dashboard/googlelsadashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "bingAdsDashboard",
        title: "Bing Ads Dashboard",
        type: "item",
        url: "/dashboard/bingadsdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "facebookAdsDashboard",
        title: "Facebook Ads Dashboard",
        type: "item",
        url: "/dashboard/facebookadsdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "locationsDashboard",
        title: "Locations Report",
        type: "item",
        url: "/dashboard/locationsdashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      },
      {
        id: "buCapacityThrottleDashboard",
        title: "Business Unit Capacity Throttle",
        type: "item",
        url: "/dashboard/bucapacitythrottledashboard",
        icon: ReportOutlined,
        perms: ["CLIENT"],
      }, */
      /*{
        id: 'diagnosticDashboard',
        title: 'Diagnostic Dashboard',
        type: 'item',
        url: '/dashboard/diagnosticdashboard',
        icon: TroubleshootOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'BudgetTrackerDash',
        title: 'Budget Tracker',
        type: 'item',
        url: '/dashboard/budgettrackerdash',
        icon: ShowChartOutlined,
        perms: ['CLIENT'],
      },*/
      {
        id: 'Invoices',
        title: 'Invoices',
        type: 'item',
        url: '/invoices',
        icon: ReceiptOutlined,
        perms: ['CLIENT'],
      },
      {
        id: 'leadAttDash',
        title: 'Lead Attribution',
        type: 'item',
        url: '/dashboard/leadattribution',
        icon: GroupOutlined,
        perms: ['CLIENT'],
      },
      /*{
        id: 'attrByFunnel',
        title: 'Attribution by Funnel',
        type: 'item',
        url: '/dashboard/attrByFunnel',
        icon: FilterOutlined,
        perms: ['CLIENT'],
      },*/
      {
        id: 'capacityThrottle',
        title: 'Capacity Throttle',
        type: 'item',
        url: '/capacitythrottle',
        icon: BuildOutlined,
        perms: ['CLIENT'],
      },
    ],
  };

  let db = {};

  db = dashboards;

  let view = user?.clientView;

  directoryTab.children = filterClientItems(view, user, [...directoryTab?.children]);
  controlsTab.children = filterClientItems(view, user, [...controlsTab?.children]);
  marketingTab.children = filterClientItems(view, user, [...marketingTab?.children]);
  dashboardTab.children = filterClientItems(view, user, [...dashboardTab?.children]);
  dataTab.children = filterClientItems(view, user, [...dataTab?.children]);

  let finalDash = [];

  const shouldShowNewDashboards = false; // TODO: Replace with user?.clientView === '83d837ba-7bbd-456a-9509-59236bc95104';

  if (shouldShowNewDashboards) {
    if (controlsTab?.children?.length) {
      finalDash.push(controlsTab);
    }
  } else {
    if (dashboardTab?.children?.length) {
      finalDash.push(dashboardTab);
    }

    /*if (marketingTab?.children?.length) {
      finalDash.push(marketingTab);
    }*/

    //dynamic dashboards
    if (db) {
      finalDash = [...finalDash, ...db];
    }
  }

  if (directoryTab?.children?.length) {
    finalDash.push(directoryTab);
  }

  if (dataTab?.children?.length) {
    finalDash.push(dataTab);
  }

  return {
    items: finalDash,
  };
}

function filterClientItems(view, user, navChildren = []) {
  return navChildren.filter((item) => {
    if (view && item?.perms?.includes('CLIENT')) {
      return item;
    } else if (!view && item?.perms?.includes(user?.type)) {
      return item;
    }
  });
}

export default Menu;
