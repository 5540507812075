import { green, yellow, red } from '@mui/material/colors';

export const ratingColors = {
  Good: green[500],
  Bad: yellow[700],
  Spam: red[500],
  default: 'gray', // Default color when no rating is available
};

// Define options for the dropdown
export const ratingOptions = [
  { value: 'Good', label: 'Good', color: green[500] },
  { value: 'Bad', label: 'Bad', color: yellow[700] },
  { value: 'Spam', label: 'Spam', color: red[500] },
];

export const adminUserTypes = [{ value: 'ADMIN', label: 'Admin' }];

export const userTypes = [
  { value: 'TENANT', label: 'Tenant' },
  { value: 'CLIENT', label: 'Client' },
];

export const PROFITFILL_USER_TYPES = {
  ADMIN: 'ADMIN',
  TENANT: 'TENANT',
  CLIENT: 'CLIENT',
};

export const leadTypeColors = [
  '#C0392B', // Dark Red
  '#FF5733', // Dark Purple
  '#2980B9', // Dark Blue
  '#27AE60', // Dark Green
  '#F39C12', // Dark Yellow
  '#D35400', // Dark Orange
  '#1ABC9C', // Dark Turquoise
  '#9B59B6', // Dark Violet
  '#34495E', // Dark Slate
  '#F1C40F', // Mustard Yellow
];

export const utmMediumColors = [
  '#16A085', // Teal
  '#E74C3C', // Red
  '#2C3E50', // Navy
  '#8E44AD', // Purple
  '#3498DB', // Blue
  '#27AE60', // Green
  '#F39C12', // Yellow
  '#D35400', // Orange
  '#9B59B6', // Violet
  '#34495E', // Slate
  '#1ABC9C', // Light Teal
  '#F1C40F', // Gold
  '#E67E22', // Pumpkin
  '#16A085', // Teal
  '#2ECC71', // Emerald Green
  '#1F618D', // Dark Blue
  '#A569BD', // Medium Purple
  '#D5DBDB', // Light Gray
  '#2980B9', // Azure Blue
  '#C0392B', // Strong Red
];

export const LEAD_FILTERS = [
  { label: 'Lead ID', name: 'id', type: 'text', options: undefined, operators: ['equals', 'not equal', 'contains'] },
  {
    label: 'Lead Name',
    name: 'name',
    type: 'text',
    options: undefined,
    operators: ['equals', 'not equal', 'contains'],
  },
  { label: 'Email', name: 'email', type: 'text', options: undefined, operators: ['equals', 'not equal', 'contains'] },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    options: undefined,
    type: 'text',
    options: undefined,
    operators: ['equals', 'not equal', 'contains'],
  },
  {
    label: 'Call Duration',
    name: 'talkTime',
    type: 'text',
    options: undefined,
    operators: ['greater than', 'less than'],
  },
  {
    label: 'Lead Types',
    name: 'type',
    type: 'multi-select',
    options: ['PHONE', 'FORMSUBMIT', 'LIVECHAT', 'ONLINEBOOKING', 'WEBCHAT'],
    operators: undefined,
  },
  {
    label: 'Lead Status',
    name: 'status',
    type: 'multi-select',
    options: ['CREATED', 'CLOSED'],
    operators: undefined,
  },
  {
    label: 'Source/Medium',
    name: 'sourceMedium',
    type: 'multi-select',
    options: [
      'google/cpc',
      'google/cpm',
      'google/direct',
      'google/organic',
      'direct',
      'facebook/cpm',
      'facebook/organic',
      'referrer',
    ],
    operators: undefined,
  },
  {
    label: 'UTM',
    name: 'utm',
    type: 'multi-select',
    options: ['promotion', 'campaign', 'term'],
    operators: undefined,
  },
];
