// api.js

import { API } from "aws-amplify";
import axios from "axios";

export const fetchRecording = async (lead_id, format = "mp3") => {
  try {
    const token = localStorage.getItem("whatconvertsRecordingAuth");
    const headers = {
      Authorization: `Basic ${token}`,
    };

    const apiUrl = `https://app.whatconverts.com/api/v1/recording?lead_id=${lead_id}`;

    const response = await axios.get(apiUrl, {
      headers,
      responseType: "arraybuffer",
    });
    // Convert the arraybuffer to a Blob
    const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
    // Create a URL for the blob
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  } catch (error) {
    console.error(`Error downloading the ${format} recording:`, error);
    return null;
  }
};

// Helper function to convert base64 to Blob
const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = Buffer.from(base64, "base64").toString("binary");
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

export const getWhatconvertsRecordingAuth = async (clientId, email) => {
  const auth = await API.post("whatconverts", "/whatconverts/authorize", {
    body: {
      clientId,
      email,
    },
  });

  return auth;
};
